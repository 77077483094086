import Swiper from "swiper"

const setOpacity = (swiper) => {
  const { width: swiperWidth, height: swiperHeight, slides, slidesSizesGrid } = swiper

  const transform = swiper.translate
  const center = -transform + swiperWidth / 2

  for (let i = 0, length = slides.length; i < length; i += 1) {
    const slideEl = slides[i]
    const slideSize = slidesSizesGrid[i]
    const slideOffset = slideEl.swiperSlideOffset
    const centerOffset = (center - slideOffset - slideSize / 2) / slideSize
    const offsetMultiplier = centerOffset

    slideEl.style.opacity = Math.max(1 - 0.6 * Math.abs(offsetMultiplier), 0.4)
  }
}

const parameters = {
  speed: 800,
  autoplay: false,
  centeredSlides: true,
  effect: 'coverflow',
  pagination: false,
  slidesPerView: 1.25,
  spaceBetween: 0,
  breakpoints: {
    768: {
      slidesPerView: 1,
      spaceBetween: 32,
    }
  },
  coverflowEffect: {
    scale: 0.85,
    depth: 0,
    rotate: 0,
    slideShadows: false
  },
  on: {
    setTransition(transition) {
      this.slides.forEach((el) => {
        el.style.transitionProperty = 'transform, opacity'
      })
    },

    setTranslate(translate) {
        setOpacity(this)
    }
  }
}

export default () => ({
  init() {
    const that = this

    const swiper = new Swiper(this.$refs.carousel, {
      ...parameters,
      navigation: {
        prevEl: this.$refs.prev,
        nextEl: this.$refs.next
      }
    })
  }
})

export const CarouselParameters = parameters