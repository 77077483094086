// https://tailwindcss.com/docs/configuration

const plugin = require('tailwindcss/plugin')

module.exports = {
  content: [
    "./index.php",
    "./app/**/*.php",
    "./resources/**/*.{php,vue,js}",
    "!./resources/blocks/*.js",
    "./config/acf.php"
  ],
  corePlugins: {
    container: false
  },
  future: {
    hoverOnlyWhenSupported: true,
  },
  theme: {
    fontFamily: {
      heading: ['Quattrocento', 'serif'],
      body: ['"Source Sans 3 Variable"', 'sans-serif'],
    },
    extend: {
      backgroundImage: {
        var: 'var(--bg)',
        heart: 'url(@/assets/images/heart-bg.svg)'
      },
      borderRadius: {
        '4xl': '32px'
      },
      colors: {
        blue: {
          DEFAULT: '#0361A3',
          dark: '#002855'
        },
        teal: {
          DEFAULT: '#A8E7D4'
        },
        gray: {
          500: '#848484'
        }
      },
      fontSize: {
        sm: ['0.875rem', {
          lineHeight: 1.2,
          letterSpacing: '0.02em'
        }],
        '2xl': ['1.5rem', {
          lineHeight: 1.5,
        }],
        '3xl': ['1.75rem', {
          lineHeight: 1.5,
        }],
        '4xl': ['2.5rem', {
          lineHeight: 1.1,
          fontWeight: 700
        }],
        '5xl': ['3.25rem', {
          lineHeight: 1.1,
          fontWeight: 700
        }]
      },
      letterSpacing: {
        wide: '0.03em'
      },
      typography: require('./tw-typography.config.js')
    },
  },
  plugins: [
    require('@tailwindcss/typography'),
    plugin(require('./tw-forms.config.js')),
    plugin(function({ addVariant }) {
      addVariant('appender', [
        '& .block-list-appender'
      ])
      addVariant('menu', [
        '& .wp-block-navigation__container',
        '& .wp-block-navigation > ul'
      ])
      addVariant('menu-item', '& > .wp-block-navigation > ul > .wp-block-navigation-link')
      addVariant('menu-link', [
        '& > .wp-block-navigation > ul > .wp-block-navigation-link > a',
        '& .wp-block-navigation-item__content > div'
      ])
      addVariant('menu-icon', ['& > .wp-block-navigation > ul > .wp-block-navigation-link > .wp-block-navigation__submenu-icon'])
    }),
  ],
}
