import Alpine from "alpinejs"

import ui from '@alpinejs/ui'
import intersect from '@alpinejs/intersect'
import focus from '@alpinejs/focus'
import collapse from '@alpinejs/collapse'

/**
 * Custom Plugins
 */
import emerge from "./_emerge"

/**
 * Stores
 */
import { default as header } from './_header'

/**
 * Reusable Data Objects
 */
import Carousel from './_carousel'

Alpine.plugin(ui)
Alpine.plugin(intersect)
Alpine.plugin(focus)
Alpine.plugin(collapse)

Alpine.plugin(emerge)

Alpine.store('header', header)
Alpine.data('Carousel', Carousel)

export default Alpine